import React, { useState } from "react";
import { t } from "i18next";
import { CustomModal } from "components";
import Button from "components/Button";
import { setSelectedTip } from "store/tipMethods/slice";
import { useDispatch, useSelector } from "react-redux";
import { Delete } from "icons";
import { CartAction } from "store/actions";
import { useUserWallet } from "hooks";
import { calculateCartGrossAmount } from "utils/Utils";

const Tips = () => {
  const dispatch = useDispatch();
  const [customTipModal, setCustomTipModal] = useState(false);
  const [customTipValue, setCustomTipValue] = useState("");
  const { selectedTip } = useSelector((state) => state.TipMethodsReducer);
  const { cart, orderGrossAmount, itemsGrossAmount } = useSelector(
    (state) => state?.cart
  );
  const userWallet = useUserWallet();
  const { discountInfo } = useSelector((state) => state.Discounts);
  const { ordered } = useSelector((state) => state.order);

  const tips = [
    { id: 1, label: "5%", value: 0.05 },
    { id: 2, label: "10%", value: 0.1 },
    { id: 3, label: "15%", value: 0.15 },
  ];

  const updateCartWithTip = (tip) => {
    dispatch(
      setSelectedTip({
        type: tip.type || "default",
        value: tip.value,
        unit: tip.unit,
      })
    );

    const cartTotals = calculateCartGrossAmount(
      cart,
      discountInfo,
      userWallet,
      ordered
    );

    dispatch(
      CartAction.updateCartPrice({
        originalPrice: cartTotals.itemsNetPrice,
        amountAfterDiscount: cartTotals.itemsGrossAmount,
        itemsGrossAmount: cartTotals.itemsGrossAmount,
        orderGrossAmount: cartTotals.orderGrossAmount,
        tipAmount: cartTotals.tipAmount,
      })
    );
  };

  const handleTip = (tip) => {
    updateCartWithTip({
      type: "default",
      value: tip.value, // Already in decimal form (0.05, 0.1, 0.15)
      unit: "percent",
    });
  };

  const handleCustomTipValue = (val) => {
    const parsedValue = parseFloat(val);
    const sanitizedValue = isNaN(parsedValue) ? 1 : Math.max(1, parsedValue);
    setCustomTipValue(parseFloat(sanitizedValue.toFixed(2))); // Limit to 2 decimal places
  };

  const handleCustomTip = () => {
    setCustomTipModal(!customTipModal);
  };

  const handleConfirmModal = () => {
    dispatch(
      setSelectedTip({
        type: "custom",
        value: parseFloat(customTipValue),
        unit: "fixed",
      })
    );
    updateCartWithTip({
      type: "custom",
      value: customTipValue,
      unit: "fixed",
    });
    setCustomTipModal(!customTipModal);
  };

  const handleCancelModal = () => {
    setCustomTipModal(!customTipModal);
  };

  const handleCancelTip = () => {
    dispatch(setSelectedTip(""));

    // Recalculate cart totals with no tip
    const cartTotals = calculateCartGrossAmount(
      cart,
      discountInfo,
      userWallet,
      ordered
    );

    // Update cart with new totals, ensuring tip amount is zero
    dispatch(
      CartAction.updateCartPrice({
        originalPrice: cartTotals.itemsNetPrice,
        amountAfterDiscount: cartTotals.itemsGrossAmount,
        itemsGrossAmount: cartTotals.itemsGrossAmount,
        orderGrossAmount: cartTotals.orderGrossAmount,
        tipAmount: 0, // Explicitly set tip to zero
      })
    );
  };

  const PencilIcon = (props) => {
    return (
      <svg
        className={`w-6 h-6 ${props?.className}`}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props} // Spread props for additional attributes
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="m3.99 16.854-1.314 3.504a.75.75 0 0 0 .966.965l3.503-1.314a3 3 0 0 0 1.068-.687L18.36 9.175s-.354-1.061-1.414-2.122c-1.06-1.06-2.122-1.414-2.122-1.414L4.677 15.786a3 3 0 0 0-.687 1.068zm12.249-12.63 1.383-1.383c.248-.248.579-.406.925-.348.487.08 1.232.322 1.934 1.025.703.703.945 1.447 1.025 1.934.058.346-.1.677-.348.925L19.774 7.76s-.353-1.06-1.414-2.12c-1.06-1.062-2.121-1.415-2.121-1.415z"
          fill="#000000"
        />
      </svg>
    );
  };

  return (
    <div className="bg-white mt-5 py-4 border-t-4 border-gray-200">
      <h4 className="text-outer-space text-17x font-semibold mx-4 mb-4">
        {t("Show some love, your tip makes their day!. (Optional)")}
      </h4>
      <div className="flex justify-between w-full">
        {(selectedTip?.type !== "custom" || !selectedTip) &&
          tips.map((tip, index) => (
            <div
              key={index}
              onClick={() => handleTip(tip)}
              className={`flex-1 mb-3 mx-2 p-4 rounded-md text-center cursor-pointer 
            border-4 border-gray-200  text-17x font-semibold
            ${
              selectedTip?.value === tip.value
                ? "text-brand-text-primary bg-brand-base-primary border-brand-base-primary"
                : "bg-white text-gray-800 hover:bg-gray-100"
            }`}
              id={`tip-${index + 1}`}
            >
              {tip.label}
            </div>
          ))}

        {selectedTip?.type === "custom" && (
          <span className="pl-4 text-17x font-semibold">
            Custom Tip: {parseFloat(selectedTip?.value)?.toFixed(2)}
          </span>
        )}
      </div>
      <div className="flex justify-between">
        <button
          onClick={handleCustomTip}
          className="bg-white text-gray-800 font-medium flex items-center text-15x space-x-2 p-2 shadow-none hover:bg-gray-100"
        >
          <PencilIcon /> <span className="ml-2">Pay Custom Tip</span>
        </button>

        {selectedTip?.value && (
          <button
            onClick={handleCancelTip}
            className="bg-white text-gray-800 font-medium flex items-center text-15x space-x-2 p-2 shadow-none hover:bg-gray-100"
          >
            <span className="flex">
              <Delete /> Remove Tip
            </span>
          </button>
        )}
      </div>

      <CustomModal
        open={customTipModal}
        setOpen={setCustomTipModal}
        bodyclasses="flex"
        overlayClasses="fixed h-screen w-screen top-0 bg-opacity-50 overflow-hidden transition ease-linear duration-700 z-40"
      >
        <div className="jsutify-center w-full items-center justify-center top-0 left-0 h-app-height modal-mask  overflow-y-auto flex">
          <div className="w-full h-fit mx-4 bg-white">
            <form
              className="py-8 px-4 xs:px-8"
              onSubmit={(e) => {
                e.preventDefault();
              }}
            >
              <div className="text-21x font-bold text-center">
                {"Add a Custom Tip"}
              </div>
              <div className="mb-6 mt-6">
                <label
                  htmlFor="custom-tip"
                  className="block text-sm font-medium text-gray-700"
                >
                  Enter Custom Tip Amount
                </label>
                <input
                  onChange={(e) => {
                    const inputValue = parseFloat(e.target.value) || 0;
                    handleCustomTipValue(Math.max(1, inputValue));
                  }}
                  value={customTipValue}
                  type="number"
                  min="1"
                  step="0.01"
                  placeholder="Enter amount"
                  className="mt-1 w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                />
              </div>
              <div className="flex justify-center">
                <div className="cursor-pointer" onClick={handleCancelModal}>
                  <Button
                    type="button"
                    classes="rounded bg-gray-300 text-gray-800 hover:bg-brand-base-primary mx-1 hover:text-brand-text-primary px-6 py-2 h-12 text-16x text-bold"
                    title={t("No")}
                  />
                </div>
                <div className="cursor-pointer" onClick={handleConfirmModal}>
                  <div className="flex justify-center bg-brand-base-primary rounded  mx-1 border border-brand-base-primary  text-brand-text-primary px-6 py-2 h-12 text-16x text-bold">
                    <Button
                      type="button"
                      classes="text-brand-text-primary text-16x text-bold"
                      title={t("Save")}
                    ></Button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </CustomModal>
    </div>
  );
};

export default Tips;

import React, { useState, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { t } from "i18next";

import PopupHeader from "components/PopupHeader";
import ErrorComponent from "components/ErrorComponent";
import Search from "components/Search";

import useGetStoreInfo from "service-hooks/useGetStoreInfo";
import useTouchMoveBlur from "hooks/useTouchMoveBlur";
import useEventTracker from "hooks/useEventTracker";
import { useWindowLocation } from "hooks";

import { logErrorOnSentry, errorNotification } from "utils/Utils";
import { useMapContext } from "contexts/googleMap";
import { getAreaCityAndCountry } from "utils/googleGeoCode";
import C from "Constants";
import { useLang } from "hooks";
import ModalCloseButton from "components/ModalCloseButton";

import { Loader } from "store/actions";
import StoreRow from "./StoreRow";
import StoreRowForPickup from "./StoreRowForPickup";
import { NotPersistAction } from "store/actions";
//HEADER
const getHeader = (header, headerLabel) => {
  if (header) return <PopupHeader heading={headerLabel} />;
};

function StoreListContainer({
  styleClasses,
  header,
  headerLabel,
  stores,
  searchKeyExtractor,
  redirectToStorePage,
  filterStores,
}) {
  const dispatch = useDispatch();
  const [searchTerm, setSearch] = useState("");
  const { modalLoader } = useSelector((state) => state.loader);
  const { storeError } = useSelector((state) => state?.errorSlice);
  const { orderType } = useSelector((state) => state?.AppReducer);
  const inputRef = useRef(null);
  useTouchMoveBlur({ inputRef });
  // document.body.style.height = "100vh";
  // document.body.style.overflowY = "hidden";

  useEventTracker({ name: "SELECT_STORE", orderType, shouldLog: true });

  // Search store
  const handleSearch = (e) => {
    let term = e.target.value?.toLowerCase();
    setSearch(term);
  };

  const filteredStores = useMemo(() => {
    return filterStores(stores, searchTerm);
  }, [filterStores, stores, searchTerm]);

  const closeModal = () => {
    dispatch(NotPersistAction.setModalClose());
    dispatch(NotPersistAction.togglePickupStoreModal(false));
  };

  return (
    <div className="max-460">
      <div className={styleClasses}>
        {getHeader(header, headerLabel)}

        <div className="px-4">
          <ModalCloseButton onClick={closeModal} />
          <Search
            inputRef={inputRef}
            label={t("Search for a store")}
            onChange={handleSearch}
          />
          {modalLoader && <div className="loader h-1">{t("Loading")}</div>}
          {storeError?.error && <ErrorComponent error={storeError?.error} />}
        </div>
        <div className="scr-area-set">
          {filteredStores?.map((store, index) => {
            return (
              <div
                key={index}
                // onClick={() => redirectToStorePage(store)}
                className="cursor-pointer"
              >
                {searchKeyExtractor(store, redirectToStorePage)}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

function StoreListForDelivery({
  styleClasses,
  header,
  headerLabel,
  stores,
  updateTemporaryLocation,
}) {
  const { shippingMethod, DomainType } = useSelector(
    (state) => state?.AppReducer
  );
  const params = useParams();
  const { currentLang } = useLang();
  const windowRouter = useWindowLocation();
  const getStoreInfoWithMenu = useGetStoreInfo();
  const { googlemapState } = useMapContext();
  const dispatch = useDispatch();

  const getAreaId = (store) => {
    if (
      shippingMethod === C.SHIPPING_METHODS.MAPPED_AREA_BASED &&
      store?.mappedAreaInfo
    ) {
      return store?._id;
    } else if (
      shippingMethod === C.SHIPPING_METHODS.AREA_BASED &&
      store?.areaID
    ) {
      return store?.areaID?._id;
    }
    return null;
  };

  const redirectToHome = (store) => {
    logErrorOnSentry(
      `On Store List Delivery AreaList No areaId or no shipping-method found for store ${shippingMethod} ${store}}`,
      "error",
      store
    );
    errorNotification(
      "We're sorry, but something went wrong. Please try again"
    );
    windowRouter(`/`, `/${params.domainName}`, DomainType);
    return null;
  };

  const redirectToStorePage = async (store) => {
    try {
      dispatch(Loader.setLoading(true));
      updateTemporaryLocation();
      if (store?.storeId?._id) {
        const areaId = getAreaId(store);
        if (
          googlemapState?.userLocation?.coordinates?.lat &&
          googlemapState?.userLocation?.coordinates?.lng &&
          store?.mappedAreaType === C.DISTANCE
        ) {
          const lat = googlemapState?.userLocation?.coordinates?.lat;
          const lng = googlemapState?.userLocation?.coordinates?.lng;
          const storeAreaName = await getAreaCityAndCountry(
            lat,
            lng,
            currentLang
          );
          const storearea = {
            ...store,
            areaName: storeAreaName,
          };
          await getStoreInfoWithMenu(store.storeId?._id, null, storearea);
        } else if (areaId) {
          await getStoreInfoWithMenu(store.storeId?._id, areaId, null);
        } else redirectToHome(store);
      } else redirectToHome(store);
    } catch (error) {
    } finally {
      dispatch(Loader.setLoading(false));
    }
  };

  // Filter stores based on search term
  const filterStores = (stores, searchTerm) => {
    if (!searchTerm) return stores;

    return stores?.filter((store) =>
      store?.storeId?.baseLanguage?.name
        ?.toLowerCase()
        ?.includes(searchTerm?.trim())
    );
  };

  const searchKeyExtractor = (store, redirectToStorePage) => {
    return (
      <StoreRow
        name={store?.storeId?.baseLanguage?.name}
        manualAddress={store?.storeId?.manualAddress}
        avgPrepTime={null}
        store={store?.storeId}
        redirectToStorePage={redirectToStorePage}
        storeObj={store}
      />
    );
  };

  return (
    <>
      <StoreListContainer
        styleClasses={styleClasses}
        header={header}
        headerLabel={headerLabel}
        stores={stores}
        searchKeyExtractor={searchKeyExtractor}
        redirectToStorePage={redirectToStorePage}
        filterStores={filterStores}
      />
    </>
  );
}

function StoreListForPickup({ styleClasses, header, headerLabel, stores }) {
  const getStoreInfoWithMenu = useGetStoreInfo();

  const redirectToStorePage = (store) => {
    if (store?._id) getStoreInfoWithMenu(store._id);
  };

  const searchKeyExtractor = (store, redirectToStorePage) => {
    return (
      <StoreRowForPickup
        name={store?.baseLanguage?.name}
        manualAddress={store?.manualAddress}
        avgPrepTime={store?.averagePrepTimeForPickup}
        redirectToStorePage={redirectToStorePage}
        store={store}
      />
    );
  };

  const filterStores = (stores, searchTerm) => {
    if (searchTerm === "" || searchTerm === null) {
      return stores;
    }

    return stores?.filter((store) =>
      store?.baseLanguage?.name?.toLowerCase()?.includes(searchTerm?.trim())
    );
  };

  return (
    <StoreListContainer
      styleClasses={styleClasses}
      header={header}
      headerLabel={headerLabel}
      stores={stores}
      searchKeyExtractor={searchKeyExtractor}
      redirectToStorePage={redirectToStorePage}
      filterStores={filterStores}
    />
  );
}

export { StoreListForDelivery, StoreListForPickup };

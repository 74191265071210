import React, { useState } from "react";
import { t } from "i18next";
import { SVGICONS } from "config";

const DineInTableOptions = ({ setSelectedPaymentOptions, children }) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const title = ["Complete Payment", "Dine First, Pay Later"];

  const handleSelectOption = (option) => {
    setSelectedOption((prev) => (prev === option ? null : option));
    setSelectedPaymentOptions(option);

    if (selectedOption !== option) {
      setTimeout(() => {
        const element = document.getElementById(`payment-type-${option}`);
        if (element) {
          const targetY = element.getBoundingClientRect().top + window.scrollY;

          let start = null;
          const duration = 4000; // 1 second

          const step = (timestamp) => {
            if (!start) start = timestamp;
            const elapsed = timestamp - start;
            const progress = Math.min(elapsed / duration, 1);

            // Smooth step calculation (ease-out effect)
            const easeOut = 1 - Math.pow(1 - progress, 3);
            window.scrollTo(
              0,
              window.scrollY + (targetY - window.scrollY) * easeOut
            );

            if (progress < 1) {
              requestAnimationFrame(step);
            }
          };

          requestAnimationFrame(step);
        }
      }, 0); // Ensure visibility updates before scrolling
    }
  };

  return (
    <div className="bg-white mt-5 py-4 border-t-4 border-gray-200">
      <h4 className="text-outer-space text-17x font-semibold mx-4 mb-4">
        {t("Select Payment Option")}
      </h4>

      {children &&
        children.map((val, index) => (
          <div
            className="mb-3 mx-4 border border-gray-200 rounded-md"
            key={`payment-type-${index + 1}`}
          >
            <div
              className={`p-4 text-black px-4 py-3 ${
                selectedOption && selectedOption === index + 1 ? "border-b" : ""
              }`}
              style={{
                borderBottomLeftRadius: "0",
                borderBottomRightRadius: "0",
              }}
              onClick={() => handleSelectOption(index + 1)}
            >
              <div className="flex justify-between items-center">
                <div className="text-16x font-normal">{t(title[index])}</div>
                <div className="flex">
                  <span className="inline-flex w-6 h-6">
                    <input type="radio" name="Select Type" className="hidden" />
                    <span className="w-6 h-6 inline-flex ">
                      {selectedOption && selectedOption === index + 1 ? (
                        <SVGICONS.RadioCheck color="text-black" />
                      ) : (
                        <SVGICONS.RadioUnCheck color="text-black" />
                      )}
                    </span>
                  </span>
                </div>
              </div>
            </div>
            {selectedOption && selectedOption === index + 1 ? (
              <div className="p-4 bg-gray-100 bg-white py-4 ">{val}</div>
            ) : null}
          </div>
        ))}
    </div>
  );
};

export default DineInTableOptions;

/* eslint-disable no-unused-expressions */
/* eslint-disable no-undef */

export const injectScript = (data) => {
  try {
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.src = data.src;
    script.setAttribute("class", "resthero-plugins-file");
    const obj = data.attr;

    if (obj && !Object.keys(obj).length !== 0 && obj.constructor === Object) {
      Object.keys(obj).forEach(function (key) {
        script.setAttribute(key, obj[key]);
      });
    }

    document.head.append(script);
  } catch (err) {
    const sample = { error: err, data: data };
    reportError("(frontend) issue in injectScript function " + sample);
  }
};

export const injectVariables = (data) => {
  try {
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.setAttribute("class", "resthero-plugins-file");
    script.text = data;
    document.head.append(script);
  } catch (err) {
    const sample = { error: err, data: data };
    reportError("(frontend) issue injectVariables function " + sample);
  }
};

export const removeAllInjectedScripts = async () => {
  while (document.querySelector("body") == null) {
    await new Promise((resolve) => setTimeout(resolve, 10));
  }

  const scripts = document.querySelectorAll(".resthero-plugins-file");
  if (scripts.length) {
    scripts.forEach((script) => {
      script.remove();
    });
  }
};

export const hasEmptyValue = (obj) => {
  for (const key in obj) {
    if (obj[key] === "" || obj[key] === null || obj[key] === undefined) {
      return true;
    }
  }
  return false;
};

export const reportError = (error) => {
  console.warn({ reportError: error });
  window?.external_SENTRY?.captureMessage &&
    window?.external_SENTRY?.captureMessage(
      "[External Integration File] " + error,
      {
        level: "error",
      }
    );
};

export const pluginInit = {
  GOOGLE_ANALYTICS: {
    init: ({ googleAnalyticsId }) => {
      injectScript({
        name: "googleAnalytics",
        src: `https://www.googletagmanager.com/gtag/js?id=${googleAnalyticsId}`,
      });
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        dataLayer.push(arguments);
      }
      gtag("js", new Date());

      gtag("config", `${googleAnalyticsId}`, {
        cookie_flags: "samesite=none;secure",
        page_location: window.location.href,
        page_title: document.title,
        page_path: document.location.href,
      });
    },
  },
  GOOGLE_TAG_MANAGER: {
    init: ({ googleTagManagerId }) => {
      (function (w, d, s, l, i) {
        w[l] = w[l] || [];
        w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });

        var f = d.getElementsByTagName(s)[0],
          j = d.createElement(s),
          dl = l != "dataLayer" ? "&l=" + l : "";
        j.async = true;
        j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
        j.setAttribute("class", "resthero-plugins-file");
        f.parentNode.insertBefore(j, f);
      })(window, document, "script", "dataLayer", `${googleTagManagerId}`);
    },
  },
  FACEBOOK_PIXEL: {
    init: ({ fbPixelId }) => {
      try {
        !(function (f, b, e, v, n, t, s) {
          if (f.fbq) return;
          n = f.fbq = function () {
            n.callMethod
              ? n.callMethod.apply(n, arguments)
              : n.queue.push(arguments);
          };
          if (!f._fbq) f._fbq = n;
          n.push = n;
          n.loaded = !0;
          n.version = "2.0";
          n.queue = [];
          t = b.createElement(e);
          t.async = !0;
          t.src = v;
          t.setAttribute("class", "resthero-plugins-file");
          s = b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t, s);
        })(
          window,
          document,
          "script",
          "https://connect.facebook.net/en_US/fbevents.js"
        );

        fbq("init", `${fbPixelId}`);
        fbq("track", "PageView");
      } catch (err) {
        reportError("(frontend) FACEBOOK_PIXEL" + err);
      }
    },
  },
  POPTIN: {
    init: ({ poptinId }) => {
      const config =
        "var poptin_single_page_app=true;var poptinAfterPageLoad = true;";
      injectVariables(config);

      injectScript({
        name: "poptin",
        src: `./plugins/poptin-pixel.js?id=${poptinId}`,
        attr: { id: "pixel-script-poptin" },
      });
    },
  },
  SNAPCHAT_PIXEL: {
    init: ({ snapchatPixelId }) => {
      try {
        (function (e, t, n) {
          if (e.snaptr) return;
          var a = (e.snaptr = function () {
            a.handleRequest
              ? a.handleRequest.apply(a, arguments)
              : a.queue.push(arguments);
          });
          a.queue = [];

          var s = "script";
          var r = t.createElement(s);
          r.async = true;
          r.src = n;

          var u = t.getElementsByTagName(s)[0];
          if (u && u.parentNode) {
            u.parentNode.insertBefore(r, u);
          } else {
            t.head.appendChild(r);
          }
        })(window, document, "https://sc-static.net/scevent.min.js");
        snaptr("init", snapchatPixelId);
        snaptr("track", "PAGE_VIEW");
      } catch (error) {
        reportError("(frontend) SNAPCHAT_PIXEL" + error);
      }
    },
  },
  TIKTOK_PIXEL: {
    init: ({ tiktokPixelId }) => {
      try {
        !(function (w, d, t) {
          w.TiktokAnalyticsObject = t;
          var ttq = (w[t] = w[t] || []);
          (ttq.methods = [
            "page",
            "track",
            "identify",
            "instances",
            "debug",
            "on",
            "off",
            "once",
            "ready",
            "alias",
            "group",
            "enableCookie",
            "disableCookie",
            "holdConsent",
            "revokeConsent",
            "grantConsent",
          ]),
            (ttq.setAndDefer = function (t, e) {
              t[e] = function () {
                t.push([e].concat(Array.prototype.slice.call(arguments, 0)));
              };
            });
          for (var i = 0; i < ttq.methods.length; i++)
            ttq.setAndDefer(ttq, ttq.methods[i]);
          (ttq.instance = function (t) {
            for (var e = ttq._i[t] || [], n = 0; n < ttq.methods.length; n++)
              ttq.setAndDefer(e, ttq.methods[n]);
            return e;
          }),
            (ttq.load = function (e, n) {
              var r = "https://analytics.tiktok.com/i18n/pixel/events.js",
                o = n && n.partner;
              (ttq._i = ttq._i || {}),
                (ttq._i[e] = []),
                (ttq._i[e]._u = r),
                (ttq._t = ttq._t || {}),
                (ttq._t[e] = +new Date()),
                (ttq._o = ttq._o || {}),
                (ttq._o[e] = n || {});
              n = document.createElement("script");
              (n.type = "text/javascript"),
                (n.async = !0),
                (n.src = r + "?sdkid=" + e + "&lib=" + t);
              e = document.getElementsByTagName("script")[0];
              e.parentNode.insertBefore(n, e);
            });

          ttq.load(tiktokPixelId);
          ttq.page();
        })(window, document, "ttq");
      } catch (error) {
        reportError("(frontend) TIKTOK_PIXEL" + error);
      }
    },
  },
};

export const InitializePlugins = () => {
  try {
    removeAllInjectedScripts();
    const brandInfo = window?.external_plugin_file_integration_brandInfo;
    if (brandInfo &&  brandInfo?.activeIntegrations?.length>0) {
      // const brandId = brandInfo.brand._id;
      const plugins = brandInfo?.activeIntegrations ?? [];
      if (!Array.isArray(plugins) || !plugins.length) {
        reportError(
          "(serverside) activeIntegrations key is not an array:" +
            plugins
        );
        return;
      }

      plugins?.forEach((plugin) => {
        if (
          plugin?.isActive &&
          Object?.keys(pluginInit)?.includes(plugin?.name) &&
          typeof plugin?.metaData !== "undefined"
        ) {
          const integration = pluginInit[plugin?.name];
          // check if metadata value is empty
          if (hasEmptyValue(plugin?.metaData)) {
            reportError("(server) metaData is Empty " + plugin?.name);
            return;
          } else {
            integration.init(plugin.metaData);
          }
        }
      });
    } 
  } catch (error) {
    reportError("Unexpected:" + error);
  }
};
